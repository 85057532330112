/**
 * This utility file is used to detect which browser or device the user is on.
 *
 * Note 1: The `ua-parser-js` library below is currently upgrading to v2 (as of this
 * time of writing it's on v2.0.0-alpha). When the library is officially on v2
 * we should update this to use the latest version. Aside from getting the latest
 * updates we will also have the ability to use tree-shaking in the new version.
 *
 * Note 2: It is not recommended to use user agent sniffing as we do here (see:
 * https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent,
 * https://developer.mozilla.org/en-US/docs/Web/API/Navigator/userAgent).
 * However we are forced to do this because feature detection isn't reliable where we use these.
 * - We need to use `isFirefox()` due to a Firefox bug for a feature that feature detection will
 *   tell us it supports (see comment in `draggable.js`).
 * - We need to use `isSafari()` because of a few Safari bugs (see comments where `isSafari()`
 *   is used).
 * - The other methods are needed to support AR Camera Navigation. Chrome on Mac OS currently fails
 *   to correctly set the exposure that we need. Other browsers support a mixed number of features,
 *   but only Edge and non-Mac Chrome seem to support all of them.
 */
import { UAParser } from 'ua-parser-js'

const uaParser = new UAParser(window.navigator.userAgent)

export function isFirefox () {
  return uaParser.getBrowser().name.includes('Firefox')
}

export function isSafari () {
  return uaParser.getBrowser().name === 'Safari'
}

export function isEdge () {
  return uaParser.getBrowser().name === 'Edge'
}

export function isChrome () {
  // 'Chrome Headless' is so this returns true for Cypress headless mode
  return uaParser.getBrowser().name === 'Chrome' || uaParser.getBrowser().name === 'Chrome Headless'
}

export function isMacOs () {
  return uaParser.getOS().name === 'Mac OS'
}

export function isMobile () {
  return uaParser.getDevice().type === 'mobile'
}

export function isIos () {
  return uaParser.getOS().name === 'iOS'
}

/**
 * Determine whether the AR Cam Nav game is supported on the user's desktop device.
 * AR Cam Nav currently only supports Edge and non-MacOS Chrome.
 *
 * Safari is not supported because we need to be able to change the camera device
 * settings to set a different contrast for the computer vision wasm app to work
 * properly. Safari does not support this feature. Also, both Safari and Firefox do
 * not support HID device access. See:
 * https://caniuse.com/webhid,
 * https://github.com/mozilla/standards-positions/issues/459
 *
 * @returns true if the desktop browser is supported for AR Cam nav; false otherwise
 */
export function hasBrowserSupportForArCamNav () {
  return (isChrome() || isEdge()) && !isMacOs() && !isMobile()
}

/**
 * Determine whether the AI auto-activities are supported on the user's device.
 * Desktop browsers are supported except for Firefox. Mobile is not supported.
 *
 * Firefox is not supported due to a browser bug with downscaling higher resolution
 * videos to 4:3 aspect ratio. See https://bugzilla.mozilla.org/show_bug.cgi?id=1286945.
 * If the above issue is resolved within Firefox, we can remove the isFirefox() check below.
 *
 * @returns true if the browser is supported for AI auto-activities; false otherwise
 */
export function hasBrowserSupportForAiActivity () {
  return !isFirefox() && !isMobile()
}
