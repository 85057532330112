import { ref, computed } from 'vue'

export function useCarouselControls () {
  const scrollDistance = 400
  const scrollAmount = ref(0)
  const rowHiddenWidth = ref(0)
  const rowScrollWidth = ref(0)

  const updateRowWidth = (row) => {
    if (row) {
      // ScrollWidth: Total width of the element, including content not visible on screen.
      // ClientWidth: Width of the element that is visible on screen.
      rowScrollWidth.value = row.scrollWidth
      rowHiddenWidth.value = row.scrollWidth - row.clientWidth
    }
  }

  const scrollRight = (row) => {
    if (rowHiddenWidth.value > scrollDistance) {
      scrollAmount.value = Math.min(scrollAmount.value + scrollDistance, rowHiddenWidth.value)
    } else if (scrollAmount.value < rowScrollWidth.value) {
      const remainingScroll = rowScrollWidth.value - scrollAmount.value
      scrollAmount.value += Math.min(scrollDistance, remainingScroll)
    }
    row.scrollTo({
      left: scrollAmount.value,
      behavior: 'smooth'
    })
  }

  const scrollLeft = (row) => {
    scrollAmount.value -= scrollDistance
    if (scrollAmount.value < 0) {
      scrollAmount.value = 0
    }
    row.scrollTo({
      left: scrollAmount.value,
      behavior: 'smooth'
    })
  }

  const updateScrollAmount = (val) => {
    scrollAmount.value = val
    if (scrollAmount.value > rowScrollWidth.value) {
      scrollAmount.value = rowScrollWidth.value
    }
  }

  const canScrollLeft = computed(() => {
    if (!rowHiddenWidth.value) return false
    return scrollAmount.value > 0
  })

  const canScrollRight = computed(() => {
    if (!rowHiddenWidth.value) return false

    if (rowHiddenWidth.value > scrollDistance) {
      return scrollAmount.value !== rowHiddenWidth.value
    }
    return scrollAmount.value + scrollDistance < rowScrollWidth.value
  })

  return {
    scrollAmount,
    rowHiddenWidth,
    scrollLeft,
    scrollRight,
    updateRowWidth,
    rowScrollWidth,
    updateScrollAmount,
    canScrollLeft,
    canScrollRight
  }
}
