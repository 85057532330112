<template>
  <div class="base-tabs-container">
    <div class="left-arrow-btn">
      <button
        v-if="isShowingButtons"
        :disabled="!showLeftBtn"
        :class="['arrow-btn', {'fade-btn': !showLeftBtn}]"
        @click="$emit('leftClick')"
      >
        <img src="@/assets/icons/chevron-left-bold.svg" />
      </button>
    </div>
    <slot />
    <div class="right-arrow-btn">
      <button
        v-if="isShowingButtons"
        :disabled="!showRightBtn"
        :class="['arrow-btn', {'fade-btn': !showRightBtn}]"
        @click="$emit('rightClick')"
      >
        <img src="@/assets/icons/right-arrow.svg" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showLeftBtn: {
      type: Boolean,
      default: true
    },
    showRightBtn: {
      type: Boolean,
      default: true
    }
  },
  emits: ['leftClick', 'rightClick'],
  computed: {
    isShowingButtons () {
      return this.showLeftBtn || this.showRightBtn
    }
  }
}
</script>

<style lang="scss" scoped>
.base-tabs-container {
  display: flex;

  .arrow-btn {
    width: 36px;
    height: 36px;
    z-index: 4;
    border-radius: 4px;
    margin-top: 15px;
  }

  .fade-btn {
    opacity: 0.35;
  }
}
</style>
