<template>
  <template v-if="to">
    <router-link :to="to">
      <div :ref="value" :class="['tab', 'link-tab', { active: isActive}]">
        <slot></slot>
      </div>
    </router-link>
  </template>
  <div
    v-else
    :ref="value"
    :class="['tab', 'regular-tab', { active: isActive }]"
    @click="handleSelectTab"
  >
    <slot></slot>
  </div>
</template>

<script>
import { randomString } from '@/utils/string-util'

export default {
  name: 'BaseTab',
  inject: ['activeTab', 'selectTab'],
  props: {
    value: {
      type: [String, Number],
      default: () => `tab-${randomString()}`
    },
    to: {
      type: String,
      default: null
    }
  },
  computed: {
    isActive () {
      if (this.to) {
        return this.$route.path.includes(this.to)
      }
      return this.activeTab === this.value
    }
  },
  mounted () {
    this.selectActiveTab()
  },
  watch: {
    $route () {
      this.selectActiveTab()
    },
    activeTab () {
      this.selectActiveTab()
    }
  },
  methods: {
    selectActiveTab () {
      if (this.isActive) {
        this.handleSelectTab()
      }
    },
    handleSelectTab () {
      this.selectTab({
        element: this.$refs[this.value],
        value: this.value
      })
    },
    handleRouterLinkClick () {
      this.handleSelectTab()
      this.$router.push(this.to)
    }
  }
}
</script>

<style lang="scss" scoped>
.tab {
  text-decoration: none;
  position: relative;
  color: $neutral-typography-medium;
  font-size: 14px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}

.tab.active {
  color: $primary-digital-teal-default;
}

.regular-tab:hover {
  background-color: $primary-digital-teal-background;
}

.link-tab:hover,
.regular-tab:not(.active):hover {
  background-color: $neutral-background-color;
}
</style>
