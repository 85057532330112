export const videoReviewerType = {
  PEER: {
    id: 1,
    value: 'PEER'
  },
  FACULTY: {
    id: 2,
    value: 'FACULTY'
  },
  AI: {
    id: 3,
    value: 'AI'
  }
}

export const videoTypeEnums = {
  SIMULATION: 'simulation',
  SURGICAL: 'surgical',
  OTHER: 'other'
}

export const videoFilterEnums = {
  ALL: 'all',
  FAVORITES: 'favorites',
  MY_VIDEOS: 'my_videos',
  SIMULATION: videoTypeEnums.SIMULATION,
  SURGICAL: videoTypeEnums.SURGICAL,
  OTHER: videoTypeEnums.OTHER
}

export const videoStatus = {
  SUBMITTED: 'SUBMITTED',
  SAVED_FOR_LATER: 'SAVED_FOR_LATER',
  SHARED_VIDEO: 'SHARED_VIDEO'
}

export const submissionStates = {
  UPLOADING: 'UPLOADING',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
  PROCESSING_UPLOAD: 'PROCESSING_UPLOAD',
  PROCESSING_STREAM: 'PROCESSING_STREAM',
  UPLOAD_FAILED: 'UPLOAD_FAILED'
}

export const submissionTypeEnums = {
  ASSESSMENT: 'ASSESSMENT',
  SHARED_ASSESSMENT: 'SHARED_ASSESSMENT',
  SHARED: 'SHARED',
  SAVE: 'SAVE'
}

export const videoStreamTypes = {
  LAPAROSCOPE: 'LAPAROSCOPE',
  FACECAM: 'FACECAM',
  AI_SETUP: 'AI_SETUP'
}

export const sharedLibraryRequestStatus = {
  ACCEPTED: 'ACCEPTED',
  DENIED: 'DENIED',
  REQUESTED: 'REQUESTED'
}

export const approachTypes = {
  LAPAROSCOPIC: 'LAPAROSCOPIC',
  ROBOTIC: 'ROBOTIC'
}

export const cancelUploadTaskRequestStatus = {
  PENDING: 'PENDING',
  CONFIRMED: 'CONFIRMED',
  DENIED: 'DENIED'
}

export const approachTypesFriendly = {
  LAPAROSCOPIC: 'Laparoscopic',
  ROBOTIC: 'Robotic'
}

export const ghostRowTypes = {
  PROCESSING_GHOST_ROW: 'PROCESSING_GHOST_ROW',
  PAUSED_GHOST_ROW: 'PAUSED_GHOST_ROW'
}

export const activityVideoType = {
  SETUP: 'SETUP',
  ACTIVITY: 'ACTIVITY'
}

export const MAX_COMMENT_SIZE = 1000

export const MAX_VIDEO_TITLE_SIZE = 150

export const MAX_VIDEO_DESCRIPTION_SIZE = 500

export const MAX_RECOMMENDED_VIDEO_COMMENT_SIZE = 255

export const MAX_REPORT_COMMENT_SIZE = 5000

export const MAX_TRUNCATED_VIDEO_DESCRIPTION_SIZE = 150
