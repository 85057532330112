import { defineStore } from 'pinia'
import { simseiApi, dispatchCheckAccessToken } from '@/residency/app-props'
import { buildBufferProxy, retransmitMissingMessages, getBufferSize, resetBuffer } from '@/residency/websocket/msg-buffer'
import Stomp from 'webstomp-client'
import log from '@/residency/utils/log'
import RecordRTC from 'recordrtc'
import { useAuthStore } from '@/residency/stores/auth'
import { useMeStore } from '@/residency/stores/me'
import { useProgramStore } from '@/residency/stores/program'

let uploadChecker

function getVideoExtension (mimeType) {
  let fileExt = 'webm'
  if (mimeType.includes('mp4')) {
    fileExt = 'mp4'
  }
  return fileExt
}

function hasBufferedData (streamState, streamType) {
  // Websocket has data waiting to be put on the wire
  if (streamState.ws.ws.bufferedAmount !== 0) {
    return true
  }

  // We have not sent all the intended video blobs.
  if (streamState.queueIndex !== streamState.uploadQueue.length) {
    return true
  }

  // Not all data has been acked, this may mean we have to retransmit those blobs.
  // Therefore, those blobs can be considered queued.
  if (getBufferSize(streamType) > 0) {
    return true
  }

  return false
}

const defaultStreamState = () => {
  return {
    id: null,
    deviceId: null,
    uploadQueue: [],
    queueIndex: 0,
    uploading: false,
    stoppedRecording: false,
    sentFinalBlob: false,
    ws: null,
    videoLength: 0,
    reconnecting: false,
    streamInterrupted: false,
    byteCount: 0,
    fullVideoBlob: null,
    segmentId: 0
  }
}

export const useVideoRecorderStore = defineStore('videoRecorder', {
  state: () => ({
    recordId: null,
    streams: {},
    reservedStreamIds: {},
    previousRecordTime: 0,
    totalByteSize: 0,
    remainingBufferSize: 0,
    uploadPercent: 0,
    backgroundUploading: false,
    finalBlobReceiptIds: {},
    cancelingVideoUpload: false
  }),
  getters: {
    usedDevices: (state) => {
      return Object.values(state.streams).map(stream => stream && stream.deviceId)
    },
    currentRecordTime: (state) => state.previousRecordTime,
    activeStreamCount: (state) => {
      return state.streams ? Object.keys(state.streams).length : 0
    }
  },
  actions: {
    /**
    * Create a video assessment row in the database and return its ID
    */
    async createVideoAsmt (payload) {
      const resp = await simseiApi.post(`/video/${payload.videoType}/start-record`, payload.videoInfo)
      const uuid = resp.data
      this.recordId = uuid
      return uuid
    },
    deleteVideoAsmt (videoAsmtId) {
      return simseiApi.delete(`/video/${videoAsmtId}`)
    },
    /**
    * Set the laparoscope or facecam stream state, ie state.streams.FACECAM or state.streams.LAPAROSCOPE.
    * These objects are defined by the `defaultStreamState` above.
    */
    createStream ({ streamType, deviceId }) {
      let streamState
      if (this.streams[streamType]) {
        streamState = this.streams[streamType]
      } else {
        streamState = defaultStreamState()
      }
      streamState.deviceId = deviceId
      this.streams[streamType] = streamState
    },
    /**
    * Delete the laparoscope or facecam stream state, ie state.streams.FACECAM or state.streams.LAPAROSCOPE.
    */
    removeStreamType (streamType) {
      this.deleteStream(streamType)
      this.totalByteSize = 0
      this.uploadPercent = 0
    },
    /**
    * Create video stream row in the database and connect a websocket to upload the stream over
    */
    async startStream ({
      streamType, frameRate, deviceId, mimeType, shouldGenerateThumbnails
    }) {
      let params = `recordId=${this.recordId}`
      params += `&streamType=${streamType}`
      params += `&frameRate=${frameRate}`
      params += `&deviceId=${deviceId}`
      params += `&mimeType=${mimeType}`
      params += `&needsThumbnails=${shouldGenerateThumbnails}`
      const resp = await simseiApi.post(`/video/start-stream?${params}`)
      const streamId = resp.data

      await dispatchCheckAccessToken()
      resetBuffer() // Reset buffer to prevent old data from being sent on reconnect
      const ws = await connectSocket(true, streamType)

      const streamState = this.streams[streamType]
      streamState.id = streamId
      streamState.ws = ws
      streamState.byteCount = 0

      this.cancelingVideoUpload = false
      this.streams[streamType] = streamState
    },
    /**
    * Queue blob to be uploaded to the back end
    */
    queueBlob (blobInfo) {
      const streamState = this.streams[blobInfo.streamType]
      if (streamState) {
        streamState.uploadQueue[streamState.segmentId] = blobInfo.blob
      }
      this.uploadBlob({ streamType: blobInfo.streamType })
      this.updateRecordLength()
      this.updateTotalSize(blobInfo.blob.size)
      streamState.segmentId++
    },
    /**
    * Set stoppedRecording on the stream and upload final blob
    */
    async endStream ({ streamType, fullVideoBlob }) {
      this.streams[streamType].fullVideoBlob = fullVideoBlob
      const streamState = this.streams[streamType]
      streamState.stoppedRecording = true
      this.uploadBlob({ streamType })
    },
    async endStreamAbruptly ({ streamType }) {
      const streamState = this.streams[streamType]
      streamState.stoppedRecording = true
      this.cancelingVideoUpload = true
      this.sendFinalBlob({ streamState, streamType })
    },
    /**
    * Update video length
    */
    updateTime (videoInfo) {
      const streamState = this.streams[videoInfo.streamType]
      if (streamState) {
        streamState.videoLength = videoInfo.videoLength
      }
    },
    /**
    * Upload blob from the state.streams.<stream state>.uploadQueue
    */
    async uploadBlob ({ streamType, force }) {
      if (this.cancelingVideoUpload) {
        return
      }

      const streamState = this.streams[streamType]
      if (!streamState || !streamState.ws) {
        log.error('streamState or streamState.ws null while uploading a blob')
        return
      }

      // This ensures that the blob won't be uploaded twice
      if (!force && streamState.uploading) return

      // Stop uploading if ws disconnected
      if (!streamState.ws.connected) {
        streamState.uploading = false
        log.error('Websocket connection disconnected while uploading')
        return
      }

      streamState.uploading = true
      const blobToUpload = streamState.uploadQueue[streamState.queueIndex]

      if (!blobToUpload) {
        if (streamState.stoppedRecording) {
          this.sendFinalBlob({ streamState, streamType, attempt: 0 })
          return
        } else {
          streamState.uploading = false
          return
        }
      }

      try {
        // per this example: https://websockets.spec.whatwg.org/#buffered-amount-example
        // this is the number of bytes of data that have been queued using calls to send()
        // but not yet transmitted to the network.
        // Therefore, by only sending messages when bufferedAmount is zero, we can ensure that
        // we send data about every 100ms OR as fast as the network can handle it.
        if (streamState.ws.ws.bufferedAmount === 0) {
          // Converts blob into a base64 string
          const arrayBuffer = await blobToUpload.arrayBuffer()
          const buf = Buffer.from(arrayBuffer)
          const base64 = buf.toString('base64')

          const { programId } = useProgramStore()

          // Constructs json and send to webservice via websockets
          const data = JSON.stringify({
            id: streamState.id,
            segment: streamState.queueIndex,
            base64: base64,
            stream: streamType,
            done: false,
            programId: programId
          })
          if (streamState.ws.ws.readyState === WebSocket.OPEN) {
            streamState.ws.send('/app/video', data)
            this.remainingBufferSize = this.remainingBufferSize - arrayBuffer.byteLength

            delete streamState.uploadQueue[streamState.queueIndex]
            streamState.queueIndex++
          }

          if (streamState.sentFinalBlob) {
            log.warn('Upload called after final blob sent')
          }
        }
        setTimeout(() => this.uploadBlob({ streamType, force: true }), 100)
      } catch (err) {
        log.error(err)
        streamState.uploading = false
        this.retryUpload(streamType)
      }
      this.updateRecordLength()
    },
    /**
    * After user stops recording, send the final blob to notify the back end that the recording is complete
    */
    async sendFinalBlob ({ streamState, streamType, attempt }) {
      // TODO: we can remove the `attempt` parameter here if we don't detect the condition below
      // after a significant amount of time (as of 5/14/2024)
      if (attempt !== 0 && attempt % 50 === 0) {
        // if attempt is unusually high, this might indicate there is a bug in the conditions above
        log.warn(`Attempted to send final blob for stream ${streamState.id} at least ${attempt} times`)
      }

      if ((!this.cancelingVideoUpload && hasBufferedData(streamState, streamType)) ||
          streamState.ws.ws.readyState !== WebSocket.OPEN) {
        // let the buffer and upload queue clear before sending the final packet
        setTimeout(() => this.sendFinalBlob({ streamState, streamType, attempt: attempt + 1 }), 100)
        return
      }
      try {
        const { programId } = useProgramStore()
        const data = {
          id: streamState.id,
          done: true,
          streamInterrupted: streamState.streamInterrupted,
          totalByteCount: streamState.byteCount,
          stream: streamType,
          programId: programId
        }
        // Wait for the final blob to be processed by waiting for the receipt
        // For more info, see this wiki page:
        // https://gitlab.simseidev.com/web-development/simsei/deployment-setup/-/wikis/WebSocket-heatbeat-and-receipts
        const expectedReceiptId = `final-blob-${streamState.id}`
        this.addReceptId(expectedReceiptId)
        streamState.ws.onreceipt = (frame) => {
          const processedReceiptId = frame.headers['receipt-id']
          this.removeReceiptId(processedReceiptId)
        }
        this.attemptSendFinalBlob({ streamState, streamType, data, expectedReceiptId, attempt: 0 })
      } catch (err) {
        this.$log.error(err)
        streamState.uploading = false
        this.retryUpload(streamType)
      }
    },
    async attemptSendFinalBlob (
      { streamState, streamType, data, expectedReceiptId, attempt }
    ) {
      try {
        // determine if we have tried to resend the final blob too many times
        if (this.finalBlobReceiptIds[expectedReceiptId] && attempt >= 6) {
          log.error(`Failed to send final blob to webservice after 6 attempts for stream ${streamState.id}.`)
          this.removeReceiptId(expectedReceiptId)
          return
        }

        if (streamState.ws.ws.readyState === WebSocket.OPEN) {
          streamState.ws.send('/app/video', JSON.stringify(data), {
            'receipt': expectedReceiptId
          })
          this.streams[streamType].sentFinalBlob = true
        } else {
          log.warn(`Attempted to send final blob to webservice but websocket is not open for stream ${streamState.id}`)
        }
        // schedule callback to retry final blob send if we don't get a receipt
        // after some time
        setTimeout(() => {
          if (this.finalBlobReceiptIds[expectedReceiptId]) {
            this.attemptSendFinalBlob({ streamState, streamType, data, expectedReceiptId, attempt: ++attempt })
          }
        }, 5000)
      } catch (err) {
        log.error(err)
        streamState.uploading = false
        this.retryUpload(streamType)
      }
    },
    removeStream (streamInfo) {
      const streamState = this.streams[streamInfo.streamType]
      if (!streamState || !streamState.ws) return

      if (streamInfo.streamId === streamState.id) {
        this.deleteStream(streamInfo.streamType)
      }
    },
    retryUpload (streamType) {
      log.info('Retrying to upload failed blob')
      setTimeout(() => {
        this.uploadBlob({ streamType })
      }, 1000)
    },
    /**
    * Check the completed upload percentage to inform the user of the upload progress
    */
    startUploadChecker () {
      this.backgroundUploading = true
      if (uploadChecker) clearInterval(uploadChecker)

      // Websockets used to stream video. There currently can be a maximum of two: Laparoscopic and Facecam
      const streams = Object.values(this.streams)

      uploadChecker = setInterval(() => {
        let wsEnded = true
        let currentBufferedAmount = 0
        // Check if both websocket connections have finished streaming
        streams.forEach(stream => {
          const socket = stream.ws.ws

          if (socket.readyState !== WebSocket.CLOSED || stream.reconnecting) wsEnded = false
          currentBufferedAmount += socket.bufferedAmount
        })

        if (wsEnded) {
          clearInterval(uploadChecker)
          this.backgroundUploading = false
          this.uploadPercent = 1
        } else if (this.totalByteSize !== 0) {
          this.uploadPercent = 1 - (this.remainingBufferSize + currentBufferedAmount) / this.totalByteSize
        }
      }, 500)
    },
    /**
    * Cancel the video recording
    */
    cancelRecording (videoType) {
      this.backgroundUploading = false
      simseiApi.delete(`/video/${videoType}/cancel/${this.recordId}`)
      Object.values(this.streams).forEach(stream => {
        if (stream.ws) {
          stream.ws.disconnect()
        }
      })
    },
    pauseRecordUpload () {
      this.cancelingVideoUpload = true
    },
    resumeRecordUpload () {
      this.cancelingVideoUpload = false
      for (const streamType in this.streams) {
        this.uploadBlob({ streamType, force: true })
      }
    },
    /**
    * Reserve the video recording device after its been selected by the user
    */
    reserveStream (streamId) {
      this.reservedStreamIds[streamId] = true
    },
    /**
    * Unreserve the video recording device to make it available for selection again
    */
    unreserveStream (streamId) {
      delete this.reservedStreamIds[streamId]
    },
    async downloadRecordedVideo ({ streamType, videoTitle }) {
      const stream = this.streams[streamType]
      const fileExt = getVideoExtension(stream.fullVideoBlob.type)
      RecordRTC.invokeSaveAsDialog(stream.fullVideoBlob, `${videoTitle}.${fileExt}`)
    },
    deleteStream (streamType) {
      const streamState = this.streams[streamType]
      if (streamState) {
        if (streamState.ws) {
          streamState.ws.disconnect()
        }
        delete this.streams[streamType]
      }
    },
    updateRecordLength () {
      if (!Object.keys(this.streams).length) return
      const streamLengths = []
      for (const id in this.streams) {
        streamLengths.push(this.streams[id].videoLength)
      }
      this.previousRecordTime = Math.max(...streamLengths)
    },
    updateTotalSize (size) {
      this.totalByteSize = this.totalByteSize + size
      this.remainingBufferSize = this.remainingBufferSize + size
    },
    addReceptId (receiptId) {
      // record the number of times we've tried to send the final blob
      if (!this.finalBlobReceiptIds[receiptId]) {
        this.finalBlobReceiptIds[receiptId] = {
          attempts: 0
        }
      } else {
        let attempts = this.finalBlobReceiptIds[receiptId].attempts
        this.finalBlobReceiptIds[receiptId] = {
          attempts: ++attempts
        }
      }
    },
    removeReceiptId (receiptId) {
      delete this.finalBlobReceiptIds[receiptId]
    },
    setStreamWs ({ ws, streamType }) {
      const streamState = this.streams[streamType]
      streamState.ws = ws
    },
    setStreamWsConnected (streamType) {
      const streamState = this.streams[streamType]
      streamState.reconnecting = false
    },
    setStreamWsReconnecting (streamType) {
      const streamState = this.streams[streamType]
      streamState.reconnecting = true
      streamState.streamInterrupted = true
    }
  }
})

/**
 * Initializes a websocket connection.
 * Websocket will retry to connect if webservice goes down or their internet is cut.
 */
const connectSocket = (initial, streamType) => {
  const authStore = useAuthStore()
  const meStore = useMeStore()
  const videoRecorderStore = useVideoRecorderStore()

  const socket = new WebSocket(process.env.VUE_APP_WEBSOCKET_API)

  // Heartbeat of 25 seconds "is in line with the following IETF recommendation for public Internet applications."
  // https://docs.spring.io/spring-framework/docs/4.1.0.RC1/spring-framework-reference/html/websocket.html
  let ws = Stomp.over(socket, { debug: false, heartbeat: { incoming: 25000, outgoing: 25000 } })
  // Wrap websocket with buffer proxy to handle message buffering/resending
  ws = buildBufferProxy(ws, streamType)

  return new Promise((resolve, reject) => {
    ws.connect(
      { Authorization: authStore.tokenInfo.accessToken },
      () => {
        ws.streamType = streamType
        resolve(ws)
        log.info(`Video WS (${streamType}) has connected for user ${meStore.id}`)
        if (!initial) {
          // Retry blob upload only if websocket disconnected previously
          console.info('Video WS has reconnected!')
          videoRecorderStore.setStreamWs({ streamType, ws })
          videoRecorderStore.setStreamWsConnected(streamType)
          videoRecorderStore.uploadBlob({ streamType })
        }

        // Retransmit any messages that were buffered and not sent
        const numOfRetransmittedMessages = retransmitMissingMessages(ws, streamType)
        if (numOfRetransmittedMessages > 0) {
          log.info(`Retransmitted ${numOfRetransmittedMessages} buffered messages`)
        }
      },
      (err) => {
        reject(err)
        videoRecorderStore.setStreamWsReconnecting(streamType)
        log.warn(`Video WS (${streamType}) has disconnected for user ${meStore.id}`)
        // Reconnect websocket after 1s
        setTimeout(() => {
          console.info(`Video WS (${streamType}) disconnected. Reconnecting...`)
          connectSocket(false, streamType)
        }, 1000)
      }
    )
  })
}
