<template>
  <div :class="['scroll-pane', {'scroll-pane-border': !hideSlider}]">
    <BaseTabGroupContainer
      :showLeftBtn="canScrollLeft"
      :showRightBtn="canScrollRight"
      @leftClick="handleScrollLeft"
      @rightClick="handleScrollRight"
    >
      <div ref="tabsWrapper" class="tabs-wrapper">
        <div class="tabs-inner">
          <slot></slot> <!-- Tabs will be injected here -->
          <div v-if="!hideSlider" class="active-line" :style="lineStyle"></div>
        </div>
      </div>
    </BaseTabGroupContainer>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useCarouselControls } from '@/components/carousel/carouselControls'
import { debounce } from '@/utils/rate-limit-util'
import BaseTabGroupContainer from '@/components/tabs/BaseTabGroupContainer'

/**
 * `BaseTabGroup` is a component that is used to create a tabbed navigation.
 * It wraps a group of tabs (`BaseTab`) and provides a way to manage the active tab.
 */
export default {
  name: 'BaseTabGroup',
  components: { BaseTabGroupContainer },
  props: {
    modelValue: {
      type: [String, Number],
      required: false,
      default: null
    },
    hideSlider: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    const {
      scrollLeft,
      scrollRight,
      updateRowWidth,
      updateScrollAmount,
      canScrollLeft,
      canScrollRight
    } = useCarouselControls()

    return {
      scrollLeft,
      scrollRight,
      updateRowWidth,
      updateScrollAmount,
      canScrollLeft,
      canScrollRight
    }
  },
  data () {
    return {
      lineStyle: {
        left: '0px',
        width: '0px'
      }
    }
  },
  provide () {
    return {
    /**
     * Computed property derived from the `modelValue` prop, injected into the `BaseTab` component.
     * It is used to determine the active tab by comparing the `value` property of `BaseTab` with this property.
     */
      activeTab: computed(() => this.modelValue),
      /**
       * Method injected into the `BaseTab`, triggered when a user clicks on a tab to make it active.
       * @param {Object} tab - The clicked tab, containing the HTML element and the tab value.
       * @param {HTMLElement} tab.element - The HTML element of the clicked tab.
       * @param {String|Number} tab.value - The value of the clicked tab. For a regular tab, this is the value passed to the `value` prop in `BaseTab`. For a router link tab, this is a unique string value.
       */
      selectTab: this.selectTab
    }
  },
  mounted () {
    this.onResize = debounce(this.updateWidths, 100)
    window.addEventListener('resize', this.onResize)
    this.updateWidths()
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    updateWidths () {
      this.updateRowWidth(this.$refs.tabsWrapper)
    },
    selectTab (tab) {
      const { element, value } = tab
      this.$emit('update:modelValue', value)

      const { offsetLeft: left, offsetWidth: width } = element
      this.updateUnderlineAndScrollPosition(left, width)
    },
    async updateUnderlineAndScrollPosition (leftValue, width) {
      await this.$nextTick()
      this.lineStyle = {
        left: `${leftValue}px`,
        width: `${width}px`,
        transition: 'left 0.3s ease, width 0.3s ease'
      }

      this.$refs.tabsWrapper.scrollTo({
        left: leftValue,
        behavior: 'smooth'
      })
      this.updateScrollAmount(leftValue)
    },
    handleScrollLeft () {
      this.scrollLeft(this.$refs.tabsWrapper)
    },
    handleScrollRight () {
      this.scrollRight(this.$refs.tabsWrapper)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/components/carousel/scss/carousel-mixin.scss";

.tabs-wrapper {
  display: flex;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  margin: 0px;
}

.active-line {
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: $primary-digital-teal-default;
}

.scroll-pane {
  @include scroll-pane($md, $xl);
}

.scroll-pane-border {
  border-bottom: 1px solid $border-grey;
}
</style>
