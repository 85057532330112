<template>
  <nav class="navbar-items-container">
    <BaseTabGroup
      optional
      hide-slider
      :height="navbarHeight"
    >
      <BaseTab
        v-for="navItem in navItems"
        :key="navItem.name"
        :to="navItem.link"
        class="header-tab"
        :data-cy="navItem.dataCy"
        @click="$emit('close')"
      >
        <div :class="['tab-container', {'active-tab': isCurrent(navItem)}]">
          {{ navItem.name }}
          <img v-if="navItem.notif" id="red-circle-icon" src="@/assets/icons/tiny-red-circle.svg">
          <span v-if="navItem.badge" class="notification-badge">{{ navItem.badge }}</span>
        </div>
        <transition name="fade">
          <div v-if="isCurrent(navItem)" class="tab-underline"></div>
        </transition>
      </BaseTab>
    </BaseTabGroup>
  </nav>
</template>

<script>
import cssVariables from '@/assets/scss/_variables.module.scss'

export default {
  name: 'DesktopNavbarTabs',
  props: {
    navItems: {
      type: Array,
      required: true
    }
  },
  emits: ['close'],
  data () {
    return {
      navbarHeight: cssVariables.navbarHeight
    }
  },
  methods: {
    isCurrent (item) {
      // Checks if the navbar link is equal to the current route path
      // If so, underline the navbar item
      const path = this.$route.path
      if (path.indexOf(item.link) === 0) {
        return true
      }

      // Navbar items also has an 'alias path'
      // Ex. '/courses' has a '/course' alias
      if (!item.alias) {
        return false
      }
      for (const link of item.alias) {
        if (path.indexOf(link) === 0) {
          return true
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_navbar.scss";

$tabs-height: 64px;

.navbar-items-container {
  width: 100%;
  height: $navbar-height;

  // Reduce width to prevent avatar from getting hidden
  @media (min-width: $md) and (max-width: ($lg - 1px)) {
    width: 65%;
  }
}

#red-circle-icon {
  position: absolute;
  height: 0.5rem;
  width: 0.5rem;
  top: -0.2rem;
  right: -0.6rem;
}

.notification-badge {
  color: $neutral-typography-medium;
  background-color: $surface-color;
  font-size: 10px;
  margin-left: 4px;
  padding: 2px 8px 3px;
  border-radius: 12px;
  border: 1px solid $bg-light-grey;
  width: 34px;
  height: 22px;
  text-align: center;
}

.active-tab {
  color: $primary-digital-teal-default !important;

  .notification-badge {
    font-family: "Open Sans Semi-Bold";
    color: $primary-digital-teal-default;
    background-color: $primary-digital-teal-background;
    border: 1px solid $primary-digital-teal-background;
  }
}

.tab-container {
  @include header-tab;

  position: relative;
  font-size: 16px;
  letter-spacing: 0px;
  color: $neutral-typography-medium;
  height: $navbar-height;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  text-align: center;

  @media (min-width: $xl) {
    padding: 0px 16px;
  }
}

.tab-underline {
  bottom: 0;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: $primary-digital-teal-default;
}

</style>
